import React, { useState } from 'react';
import Footer from './Footer'; // Importa el Footer

function Registration() {
  const [selectedOption, setSelectedOption] = useState('');
  const [formData, setFormData] = useState({
    nombre: '',
    modalidad: '',
    categoria: '',
    forma: '',
    duracion: '',
    musica: '',
    participaCoreografia: false,
  });

  const [alumnos, setAlumnos] = useState([
    { id: 1, nombre: 'Juan Pérez' },
    { id: 2, nombre: 'María Gómez' },
    { id: 3, nombre: 'Carlos Sánchez' },
  ]);

  const [selectedAlumnos, setSelectedAlumnos] = useState([]);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    setFormData({ ...formData, participaCoreografia: checked });
  };

  const handleAlumnoSelect = (id, rol) => {
    const alumno = alumnos.find((alumno) => alumno.id === id);
    if (!alumno) return;

    setSelectedAlumnos((prevSelected) => {
      const existing = prevSelected.find((item) => item.id === id);
      if (existing) {
        existing.roles.push(rol);
        return [...prevSelected];
      }
      return [...prevSelected, { ...alumno, roles: [rol] }];
    });
  };

  const handleAlumnoRemove = (id) => {
    setSelectedAlumnos((prevSelected) =>
      prevSelected.filter((alumno) => alumno.id !== id)
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Formulario enviado:', formData);
  };

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '20px',
    },
    buttonContainer: {
      display: 'flex',
      gap: '20px',
      margin: '20px 0',
    },
    formContainer: {
      marginTop: '20px',
      padding: '20px',
      border: '1px solid #ddd',
      borderRadius: '5px',
      width: '80%',
      maxWidth: '800px',
      textAlign: 'left',
      backgroundColor: '#f9f9f9',
    },
    input: {
      marginBottom: '15px',
      padding: '10px',
      fontSize: '16px',
      width: '100%',
      maxWidth: '400px',
      borderRadius: '5px',
      border: '1px solid #ccc',
    },
    select: {
      marginBottom: '15px',
      padding: '10px',
      fontSize: '16px',
      width: '100%',
      maxWidth: '400px',
      borderRadius: '5px',
      border: '1px solid #ccc',
    },
    section: {
      marginTop: '20px',
      padding: '15px',
      border: '1px solid #ddd',
      borderRadius: '5px',
      backgroundColor: '#f9f9f9',
    },
    sectionTitle: {
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 'bold',
      marginBottom: '15px',
    },
    gridContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: '20px',
      marginBottom: '20px',
    },
    gridItem: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
    },
    checkboxContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
    },
    submitButton: {
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: '10px 20px',
      borderRadius: '5px',
      backgroundColor: '#28a745',
      color: 'white',
      border: '1px solid #28a745',
      cursor: 'pointer',
      fontSize: '16px',
      width: '150px',
      marginTop: '20px',
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',
      marginTop: '20px',
    },
    tableCell: {
      border: '1px solid #ddd',
      padding: '8px',
      textAlign: 'center',
    },
    tableHeader: {
      backgroundColor: '#f2f2f2',
      fontWeight: 'bold',
    },
    tableRow: {
      textAlign: 'center',
    },
    buttonSpacing: {
      marginRight: '10px', // Agrega un margen entre los botones
    },
  };

  return (
    <div style={styles.container}>
      <h2>Inscripciones</h2>
      <p>Seleccione una opción:</p>
      <div style={styles.buttonContainer}>
        <button
          className="btn btn-primary"
          onClick={() => handleOptionChange('obras')}
          style={styles.buttonSpacing}
        >
          Inscribir o administrar mis obras del certamen
        </button>
        <button
          className="btn btn-secondary"
          onClick={() => handleOptionChange('seminarios')}
          style={styles.buttonSpacing}
        >
          Inscribir o administrar los participantes de seminarios
        </button>
      </div>

      {selectedOption === 'obras' && (
        <div style={styles.formContainer}>
          <form onSubmit={handleSubmit}>
            {/* Sección 1: Edición de Obra */}
            <div style={styles.section}>
              <h3 style={styles.sectionTitle}>Edición de Obra</h3>
              <div style={styles.gridContainer}>
                <div style={styles.gridItem}>
                  <label htmlFor="nombre">Nombre de la Obra:</label>
                  <input
                    type="text"
                    id="nombre"
                    name="nombre"
                    value={formData.nombre}
                    onChange={handleInputChange}
                    style={styles.input}
                  />
                </div>
                <div style={styles.gridItem}>
                  <label htmlFor="modalidad">Modalidad:</label>
                  <select
                    name="modalidad"
                    id="modalidad"
                    value={formData.modalidad}
                    onChange={handleInputChange}
                    style={styles.select}
                  >
                    <option value="">Selecciona modalidad</option>
                    <option value="modalidad1">Modalidad 1</option>
                    <option value="modalidad2">Modalidad 2</option>
                  </select>
                </div>
                <div style={styles.gridItem}>
                  <label htmlFor="categoria">Categoría:</label>
                  <select
                    name="categoria"
                    id="categoria"
                    value={formData.categoria}
                    onChange={handleInputChange}
                    style={styles.select}
                  >
                    <option value="">Selecciona categoría</option>
                    <option value="categoria1">Categoría 1</option>
                    <option value="categoria2">Categoría 2</option>
                  </select>
                </div>
                <div style={styles.gridItem}>
                  <label htmlFor="forma">Forma:</label>
                  <select
                    name="forma"
                    id="forma"
                    value={formData.forma}
                    onChange={handleInputChange}
                    style={styles.select}
                  >
                    <option value="">Selecciona forma</option>
                    <option value="forma1">Forma 1</option>
                    <option value="forma2">Forma 2</option>
                  </select>
                </div>
                <div style={styles.gridItem}>
                  <label htmlFor="duracion">Duración (minutos):</label>
                  <input
                    type="number"
                    id="duracion"
                    name="duracion"
                    value={formData.duracion}
                    onChange={handleInputChange}
                    style={styles.input}
                  />
                </div>
                <div style={styles.gridItem}>
                  <label htmlFor="musica">Nombre de la música:</label>
                  <input
                    type="text"
                    id="musica"
                    name="musica"
                    value={formData.musica}
                    onChange={handleInputChange}
                    style={styles.input}
                  />
                </div>
                <div style={styles.checkboxContainer}>
                  <input
                    type="checkbox"
                    name="participaCoreografia"
                    checked={formData.participaCoreografia}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="participaCoreografia">
                    Participa por Coreografía
                  </label>
                </div>
              </div>
            </div>

            {/* Sección 2: Listado de Alumnos */}
            <div style={styles.section}>
              <h3 style={styles.sectionTitle}>Listado de Alumnos</h3>
              <table style={styles.table}>
                <thead>
                  <tr style={styles.tableHeader}>
                    <th style={styles.tableCell}>Nombre</th>
                    <th style={styles.tableCell}>Seleccionar Rol</th>
                  </tr>
                </thead>
                <tbody>
                  {alumnos.map((alumno) => (
                    <tr key={alumno.id} style={styles.tableRow}>
                      <td style={styles.tableCell}>{alumno.nombre}</td>
                      <td style={styles.tableCell}>
                        <button
                          className="btn btn-warning"
                          onClick={() => handleAlumnoSelect(alumno.id, 'Bailarín')}
                          style={styles.buttonSpacing}
                        >
                          Bailarín
                        </button>
                        <button
                          className="btn btn-danger"
                          onClick={() => handleAlumnoSelect(alumno.id, 'Preparador')}
                          style={styles.buttonSpacing}
                        >
                          Preparador
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={() => handleAlumnoSelect(alumno.id, 'Coreógrafo')}
                          style={styles.buttonSpacing}
                        >
                          Coreógrafo
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Sección 3: Participantes de la Obra */}
            <div style={styles.section}>
              <h3 style={styles.sectionTitle}>Participantes de la Obra</h3>
              <table style={styles.table}>
                <thead>
                  <tr style={styles.tableHeader}>
                    <th style={styles.tableCell}>Nombre</th>
                    <th style={styles.tableCell}>Rol</th>
                    <th style={styles.tableCell}>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedAlumnos.map((alumno) => (
                    <tr key={alumno.id} style={styles.tableRow}>
                      <td style={styles.tableCell}>{alumno.nombre}</td>
                      <td style={styles.tableCell}>
                        {alumno.roles.join(', ')}
                      </td>
                      <td style={styles.tableCell}>
                        <button
                          className="btn btn-danger"
                          onClick={() => handleAlumnoRemove(alumno.id)}
                        >
                          <i className="fas fa-trash-alt"></i> {/* Icono de papelera */}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Botón para guardar */}
            <button type="submit" style={styles.submitButton}>
              Guardar Obra
            </button>
          </form>
          <Footer /> {/* Agregado el Footer */}
        </div>
      )}
    </div>
  );
}

export default Registration;
