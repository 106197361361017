import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';

function Eventos() {
  const [events, setEvents] = useState(['Evento 1', 'Evento 2', 'Evento 3']);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [newEvent, setNewEvent] = useState('');
  const [editingEvent, setEditingEvent] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  const navigate = useNavigate();

  const handleSelectChange = (e) => {
    setSelectedEvent(e.target.value);
  };

  const handleNext = () => {
    if (selectedEvent) {
      alert(`Seleccionaste el evento: ${selectedEvent}`);
      navigate(`/students?event=${encodeURIComponent(selectedEvent)}`);
    } else {
      alert('Por favor, selecciona un evento antes de continuar.');
    }
  };

  const handleAddEvent = () => {
    if (newEvent.trim() === '') {
      alert('El nombre del evento no puede estar vacío.');
      return;
    }
    setEvents([...events, newEvent]);
    setNewEvent('');
  };

  const handleDeleteEvent = (eventToDelete) => {
    setEvents(events.filter((event) => event !== eventToDelete));
    if (selectedEvent === eventToDelete) setSelectedEvent('');
  };

  const handleEditEvent = (eventToEdit) => {
    setEditingEvent(eventToEdit);
    setNewEvent(eventToEdit);
    setIsEditing(true);
  };

  const handleUpdateEvent = () => {
    setEvents(events.map((event) => (event === editingEvent ? newEvent : event)));
    setEditingEvent('');
    setNewEvent('');
    setIsEditing(false);
  };

  return (
    <div style={{ ...styles.container, paddingBottom: '50px' }}>
      <h1 style={styles.title}>Gestión de Eventos</h1>
      
      {/* Formulario para agregar o editar eventos */}
      <div style={styles.formContainer}>
        <input
          type="text"
          value={newEvent}
          onChange={(e) => setNewEvent(e.target.value)}
          placeholder="Nombre del evento"
          style={styles.input}
        />
        {isEditing ? (
          <button onClick={handleUpdateEvent} style={styles.button}>
            Actualizar
          </button>
        ) : (
          <button onClick={handleAddEvent} style={styles.button}>
            Agregar
          </button>
        )}
      </div>

      {/* Selector de eventos */}
      <div style={styles.selectorContainer}>
        <select value={selectedEvent} onChange={handleSelectChange} style={styles.select}>
          <option value="" disabled>Elige un evento</option>
          {events.map((event, index) => (
            <option key={index} value={event}>
              {event}
            </option>
          ))}
        </select>
        <button onClick={handleNext} style={styles.button}>
          Siguiente
        </button>
      </div>

      {/* Lista de eventos con acciones */}
      <ul style={styles.list}>
        {events.map((event, index) => (
          <li key={index} style={styles.listItem}>
            {event}
            <button onClick={() => handleEditEvent(event)} style={styles.editButton}>
              Editar
            </button>
            <button onClick={() => handleDeleteEvent(event)} style={styles.deleteButton}>
              Eliminar
            </button>
          </li>
        ))}
      </ul>

      <Footer />
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
  },
  title: {
    fontSize: '24px',
    marginBottom: '20px',
    color: '#333',
  },
  formContainer: {
    display: 'flex',
    gap: '10px',
    marginBottom: '20px',
  },
  input: {
    padding: '10px',
    fontSize: '16px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    width: '200px',
  },
  selectorContainer: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    marginBottom: '20px',
  },
  select: {
    padding: '10px',
    fontSize: '16px',
    borderRadius: '5px',
    border: '1px solid #ccc',
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    backgroundColor: '#4CAF50',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
  list: {
    listStyleType: 'none',
    padding: 0,
  },
  listItem: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    marginBottom: '10px',
  },
  editButton: {
    backgroundColor: '#FFD700',
    padding: '5px 10px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  deleteButton: {
    backgroundColor: '#FF6347',
    padding: '5px 10px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default Eventos;
