import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

const Body = ({ children }) => {
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh", // Asegura que ocupe toda la altura de la pantalla
    },
    content: {
      flex: 1, // Hace que este contenedor crezca para llenar el espacio disponible
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "20px",
      backgroundColor: "#f9f9f9",
    },
  };

  return (
    <div style={styles.container}>
      <Navbar />
      <main style={styles.content}>{children}</main>
      <Footer />
    </div>
  );
};

export default Body;
