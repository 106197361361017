import React from 'react';

const Footer = () => {
  const styles = {
    footer: {
      textAlign: 'center',
      padding: '10px',
      backgroundColor: '#f8f9fa',
      color: '#6c757d',
      position: 'fixed',
      bottom: 0,
      width: '100%',
      fontSize: '14px',
      borderTop: '1px solid #e0e0e0',
    },
  };

  return (
    <footer style={styles.footer}>
      Copyright © ECtech-Software
    </footer>
  );
};

export default Footer;
