import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAuthProvider } from "../customHooks/authContext";



function Navbar() {
  const styles = {
    navbar: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px 20px',
      backgroundColor: '#333',
      color: '#fff',
    },
    navLinks: {
      display: 'flex',
      gap: '15px',
    },
    link: {
      color: '#fff',
      textDecoration: 'none',
      fontSize: '16px',
      padding: '5px 10px',
      borderRadius: '5px',
      transition: 'background-color 0.3s',
    },
    activeLink: {
      backgroundColor: '#007bff',
    },
  };

  const { logout } = useAuthProvider();

  return (
    <nav style={styles.navbar}>
      <h1>Gestión de Inscripciones</h1>
      <div style={styles.navLinks}>
        <NavLink 
          to="/events" 
          style={({ isActive }) => (isActive ? { ...styles.link, ...styles.activeLink } : styles.link)}
        >
          Eventos
        </NavLink>
        <NavLink 
          to="/students" 
          style={({ isActive }) => (isActive ? { ...styles.link, ...styles.activeLink } : styles.link)}
        >
          Alumnos
        </NavLink>
        <NavLink 
          to="/registration" 
          style={({ isActive }) => (isActive ? { ...styles.link, ...styles.activeLink } : styles.link)}
        >
          Inscripciones
        </NavLink>
        <NavLink 
          to="/pagos" 
          style={({ isActive }) => (isActive ? { ...styles.link, ...styles.activeLink } : styles.link)}
        >
          Pagos
        </NavLink>
        <NavLink 
          to="/settings" 
          style={({ isActive }) => (isActive ? { ...styles.link, ...styles.activeLink } : styles.link)}
        >
          Configuración
        </NavLink>
        <NavLink
          to="/login"
          onClick={() => logout()}
          style={({ isActive }) => (isActive ? { ...styles.link, ...styles.activeLink } : styles.link)}
          >
          Salir
        </NavLink>
      </div>
    </nav>
  );
}

export default Navbar;
