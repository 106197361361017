import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../customHooks/useAuth";

function Login() {
  const navigate = useNavigate();
  const { authenticate, loading, error: hookError } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [customError, setCustomError] = useState("");

  const handleLogin = async () => {
    try {
      const credentials = { email, password };
      const data = await authenticate(credentials);
      navigate("/events");
    } catch (err) {
      setCustomError("Usuario o contraseña incorrectos");
    }
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      backgroundImage: "url(https://img3.wallspic.com/previews/4/2/1/1/11124/11124-modo_monocromo-ilustracion-diseno_grafico-el_arte_abstracto-boceto-x750.jpg)",
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    loginBox: {
      backgroundColor: "rgba(255, 255, 255, 0.8)",
      padding: "20px",
      borderRadius: "10px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      textAlign: "center",
      width: "300px",
    },
    input: {
      width: "100%",
      padding: "10px",
      margin: "10px 0",
      borderRadius: "5px",
      border: "1px solid #ccc",
      boxSizing: "border-box",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "space-between",
      gap: "10px",
      marginTop: "10px",
      width: "100%",
    },
    button: {
      flex: 1,
      padding: "10px",
      borderRadius: "5px",
      border: "none",
      backgroundColor: "#007bff",
      color: "#fff",
      cursor: "pointer",
    },
    cancelButton: {
      backgroundColor: "#dc3545",
    },
    link: {
      marginTop: "10px",
      color: "#007bff",
      textDecoration: "none",
    },
    error: {
      color: "red",
      marginTop: "10px",
    },
    footer: {
      marginTop: "20px",
      color: "#555",
      fontSize: "12px",
      textAlign: "center",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.loginBox}>
        <h2>Iniciar Sesión</h2>
        <input
          type="email"
          placeholder="Correo electrónico"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={styles.input}
        />
        <input
          type="password"
          placeholder="Contraseña"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={styles.input}
        />
        <div style={styles.buttonContainer}>
          <button onClick={handleLogin} style={styles.button} disabled={loading}>
            {loading ? "Cargando..." : "Acceder"}
          </button>
          <button
            onClick={() => {
              setEmail("");
              setPassword("");
              setCustomError("");
            }}
            style={{ ...styles.button, ...styles.cancelButton }}
          >
            Cancelar
          </button>
        </div>
        <a href="#" style={styles.link}>
          ¿Olvidaste tu contraseña?
        </a>
        {(customError || hookError) && <div style={styles.error}>{customError || hookError}</div>}
      </div>
      <footer style={styles.footer}>
        &copy; {new Date().getFullYear()} Ectech-Software. Todos los derechos reservados.
      </footer>
    </div>
  );
}

export default Login;
