import axios from "axios";
import qs from "qs";

const axiosInstance = axios.create({
  baseURL: "http://ectech-software.com.ar:8000", // Base URL configurada
});

export const login = async (credentials) => {
  console.log("Credenciales en Login.js: ", credentials);

  // Convertir los datos a x-www-form-urlencoded
  const formData = qs.stringify({
    username: credentials.email,
    password: credentials.password,
  });

  try {
    const response = await axiosInstance.post(
      "/api/auth/login", 
      formData, 
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded", // Header igual a Postman
        },
      }
    );
    console.log("Respuesta:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error de autenticación:", error.response?.data || error.message);
    throw error.response ? error.response.data : error;
  }
};

export default axiosInstance;


