import React, { useState } from 'react';
import Footer from './Footer'; // Importa el Footer

function Students() {
  const [students, setStudents] = useState([
    { id: 1, name: 'Juan Pérez', identification: '12345', birthDate: '2000-01-01' },
    { id: 2, name: 'María López', identification: '67890', birthDate: '1998-05-12' },
  ]);

  const [newStudent, setNewStudent] = useState({
    firstName: '',
    lastName: '',
    identification: '',
    birthDate: '',
  });

  // Validar que el nombre y apellido comiencen con mayúscula
  const isValidName = (name) => /^[A-ZÁÉÍÓÚÑ][a-záéíóúñ]+$/.test(name);

  // Validar que la identificación solo tenga números
  const isValidIdentification = (identification) => /^\d+$/.test(identification);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewStudent({ ...newStudent, [name]: value });
  };

  const handleAddStudent = () => {
    const { firstName, lastName, identification, birthDate } = newStudent;

    if (!firstName || !lastName || !identification || !birthDate) {
      alert('Por favor, completa todos los campos.');
      return;
    }

    if (!isValidName(firstName) || !isValidName(lastName)) {
      alert('El nombre y apellido deben comenzar con una letra mayúscula.');
      return;
    }

    if (!isValidIdentification(identification)) {
      alert('La identificación debe contener solo números.');
      return;
    }

    const newId = students.length > 0 ? students[students.length - 1].id + 1 : 1;
    const fullName = `${firstName} ${lastName}`;
    setStudents([...students, { id: newId, name: fullName, identification, birthDate }]);

    // Limpiar el formulario
    setNewStudent({ firstName: '', lastName: '', identification: '', birthDate: '' });
  };

  const handleModify = (id) => {
    const student = students.find((s) => s.id === id);
    alert(`Modificar alumno: ${student.name}`);
    // Aquí puedes agregar la lógica para modificar el alumno
  };

  const handleDelete = (id) => {
    if (window.confirm('¿Estás seguro de que deseas eliminar este alumno?')) {
      setStudents(students.filter((student) => student.id !== id));
    }
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Gestión de Alumnos</h1>

      {/* Formulario para agregar alumnos */}
      <div style={styles.formContainer}>
        <input
          type="text"
          name="firstName"
          value={newStudent.firstName}
          onChange={handleInputChange}
          placeholder="Nombre"
          style={styles.input}
        />
        <input
          type="text"
          name="lastName"
          value={newStudent.lastName}
          onChange={handleInputChange}
          placeholder="Apellido"
          style={styles.input}
        />
        <input
          type="text"
          name="identification"
          value={newStudent.identification}
          onChange={handleInputChange}
          placeholder="Identificación (solo números)"
          style={styles.input}
        />
        <input
          type="date"
          name="birthDate"
          value={newStudent.birthDate}
          onChange={handleInputChange}
          style={styles.input}
        />
        <button onClick={handleAddStudent} style={styles.addButton}>
          Agregar Alumno
        </button>
      </div>

      {/* Tabla de alumnos */}
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.th}>Nombre</th>
            <th style={styles.th}>Identificación</th>
            <th style={styles.th}>Fecha de Nacimiento</th>
            <th style={styles.th}>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {students.map((student) => (
            <tr key={student.id}>
              <td style={styles.td}>{student.name}</td>
              <td style={styles.td}>{student.identification}</td>
              <td style={styles.td}>{student.birthDate}</td>
              <td style={styles.td}>
                <button
                  onClick={() => handleModify(student.id)}
                  style={{ ...styles.button, ...styles.modifyButton }}
                >
                  Modificar
                </button>
                <button
                  onClick={() => handleDelete(student.id)}
                  style={{ ...styles.button, ...styles.deleteButton }}
                >
                  Eliminar
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Footer /> {/* Agregado el Footer */}
    </div>
  );
}

// Estilos
const styles = {
  container: {
    padding: '20px',
  },
  title: {
    textAlign: 'center',
    marginBottom: '20px',
    color: '#333',
  },
  formContainer: {
    display: 'flex',
    gap: '10px',
    marginBottom: '20px',
  },
  input: {
    padding: '10px',
    fontSize: '14px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    flex: '1',
  },
  addButton: {
    padding: '10px 20px',
    fontSize: '14px',
    backgroundColor: '#4CAF50',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: '20px',
  },
  th: {
    backgroundColor: '#f4f4f9',
    padding: '10px',
    borderBottom: '1px solid #ddd',
    textAlign: 'left',
  },
  td: {
    padding: '10px',
    borderBottom: '1px solid #ddd',
    textAlign: 'left',
  },
  button: {
    padding: '5px 10px',
    fontSize: '14px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    marginRight: '5px',
    transition: 'background-color 0.3s',
  },
  modifyButton: {
    backgroundColor: '#4CAF50',
    color: '#fff',
  },
  deleteButton: {
    backgroundColor: '#f44336',
    color: '#fff',
  },
};

export default Students;
