import { useState } from "react";
import { login as loginService } from "../services/services";
import { useAuthProvider } from "../customHooks/authContext";

const useAuth = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { login, logout, isAuthenticated } = useAuthProvider();

  const authenticate = async (credentials) => {
    setLoading(true);
    setError(null);
    try {
      const data = await loginService(credentials);
      const token = data.accessToken; // El backend debe devolver "accessToken"
      if (token) {
        login(token); // Guarda el token y actualiza `isAuthenticated`
      }
      return data;
    } catch (err) {
      setError(err.message || "An error occurred");
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return { authenticate, loading, error, logout, isAuthenticated };
};

export default useAuth;


