import React from "react";
import { Navigate } from "react-router-dom";
import { useAuthProvider } from "../customHooks/authContext";

function ProtectedRoute({ children }) {
  const { isAuthenticated } = useAuthProvider();

  console.log("protectedRoute: ",isAuthenticated)

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children;
}

export default ProtectedRoute;
