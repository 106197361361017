import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Login from "./components/Login";
import Body from "./components/Body";
import Eventos from "./components/Events";
import Students from "./components/Students";
import Registration from "./components/Registration";
import ProtectedRoute from "./components/ProtectedRoute";
import { AuthProvider } from "./customHooks/authContext";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Ruta por defecto al Login */}
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />

          {/* Rutas protegidas */}
          <Route
            path="/events"
            element={
              <ProtectedRoute>
                <Body>
                  <Eventos/>
                </Body>
              </ProtectedRoute>
            }
          />
          <Route
            path="/students"
            element={
              <ProtectedRoute>
                <Body>
                <Students />
                </Body>
              </ProtectedRoute>
            }
          />
          <Route
            path="/registration"
            element={
              <ProtectedRoute>
                <Body>
                <Registration />
                </Body>
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
